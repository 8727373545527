<template>
  <div class="d-flex flex-column flex-root page-error h-100">
    <!--begin::Error-->
    <div class="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center flex-column"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!--begin::Header-->
      <header class="error-header pl-12">
        <h1 class="header-logo">
          <a href="/" title="홈" class="header-link">
            <span class="logo"></span>
          </a>
        </h1>
      </header>
      <!--end::Header-->
      <!--begin::Content-->
      <div class="error-body container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
        <h1 class="content-title font-weight-bold" style="font-size:8rem; line-height: 0.8;">404<br/><span style="font-size:4rem;">PAGE NOT FOUND</span></h1>
        <h2 class="content-subtext font-weight-bold" style="font-size:1.538rem;">Go back to the <u><a href="/" title="홈" class="header-link">main page</a></u></h2>
        <p class="browser-text" style="font-size:1.077rem;">The page you visited has an invalid or deleted address.</p>
      </div>
      <!--end::Content-->
    </div>
    <!--end::Error-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-5.scss";
</style>

<script>
export default {
  name: "Error-5",
  mounted() {},
  computed: {
    backgroundImage() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/error/bg.jpg";
    }
  }
};
</script>
